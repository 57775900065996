<template>
  <div class="Integration">
    <div class="Integration-banner">
      <img :src="require('@imgs/people/banner.png')" v-if="$isMobile()" />
      <img :src="require('@imgs/people/banner.png')" v-else>
      <div class="Integration-banner-title">
        <h3>专家介绍</h3>
        <!-- <span>NTRODUCTION EXPERTS</span> -->
      </div>
    </div>
    <div class="Integration-breadcrumb">
      当前位置:<span @click="$router.push({path: '/'})">首页</span> -> <span>专家介绍</span>
    </div>
    <div class="Integration-content">
      <div :style="!$isMobile() ? 'display:flex;flex-wrap:wrap;' : 'display:flex;flex-wrap:wrap;justify-content:center;'">
        <people-item v-for="(item,index) in peoples" :key="index" :info="item" style="margin-top:12px;"></people-item>
      </div>
      <page :total="total" @pageChange="pageChange" :pageSize="15"></page>
    </div>
  </div>
</template>
<script>
import page from '@components/page/page'
import PeopleItem from './people-item.vue'
export default {
  name: 'Integration',
  components: {
    page,
    PeopleItem
  },
  data () {
    return {
      peoples: [],
      total: 0
    }
  },
  beforeMount () {
    this.getPeoples(1)
  },
  methods: {
    getPeoples (pageNum) {
      this.$http({
        url: '/expert/list',
        params: {
          pageNum,
          pageSize: 15
        }
      }).then(res => {
        this.peoples = res.rows
        this.total = res.total
      })
    },
    pageChange (pageNum) {
      this.getPeoples(pageNum)
    }
  }
}
</script>
<style scoped lang="scss">
.Integration {
  position: relative;
  &-banner {
    width: 100%;
    position: relative;
    width: 100%;
    img {
      width: 100%;
    }
    &-title {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      text-align: center;
      h3 {
        font-size:22px;
        font-weight: 400;
        color: #ffffff;
        line-height: .8;
      font-family: Microsoft YaHei;
      }
      span {
        font-size: 1em;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
      font-family: Microsoft YaHei;
      }
    }
  }
  &-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    margin-bottom: 40px;
  }
  &-header {
    margin-top: 40px;
    align-items: center;
  }
  &-breadcrumb {
    max-width: 1200px;
    margin: 0 auto;
    padding: 12px 0;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    text-align: left;
    cursor: pointer;
    font-family: Microsoft YaHei;
  }
}
.Integration-content-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
</style>